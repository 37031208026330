<template>
    <el-form ref="form" :model="form" :rules="rules" class="x-edit" >
        <x-divider title="基本设置"/>
        <el-form-item label="沙箱测试" prop="sxcs" class="line">
            <x-switch v-model="form.sxcs" dictType="T_YES_OR_NO"/>
        </el-form-item>
<!--        <el-form-item label="所属门店" prop="guiShuMenDian" class="w50">-->
<!--            <el-select v-model="form.mdid" size="small" @change="mdchange">-->
<!--                <el-option v-for="item in options" :key="item.id" :label="item.menDianMC"-->
<!--                           :value="item.id"></el-option>-->
<!--            </el-select>-->
<!--        </el-form-item>-->
        <x-divider title="支付宝设置"/>
        <el-form-item label="AppId" prop="aliAppId" class="line">
            <el-input v-model="form.aliAppId" size="small" maxlength="200" show-word-limit/>
        </el-form-item>
        <el-form-item label="私钥" prop="aliPrivateKey" class="line">
            <el-input v-model="form.aliPrivateKey" type="textarea" :rows="4" size="small" maxlength="2000" show-word-limit/>
        </el-form-item>
        <el-form-item label="公钥" prop="aliPubilcKey" class="line">
            <el-input v-model="form.aliPubilcKey" type="textarea" :rows="4" size="small" maxlength="2000" show-word-limit/>
        </el-form-item>
        <x-divider title="微信设置"/>
        <el-form-item label="ApiKey" prop="wxApiKey" class="line">
            <el-input v-model="form.wxApiKey" size="small" maxlength="500" show-word-limit/>
        </el-form-item>
        <el-form-item label="AppId" prop="wxAppId" class="line">
            <el-input v-model="form.wxAppId" size="small" maxlength="200" show-word-limit/>
        </el-form-item>
        <el-form-item label="商户ID" prop="wxMchId" class="line">
            <el-input v-model="form.wxMchId" size="small" maxlength="200" show-word-limit/>
        </el-form-item>
        <el-form-item class="line">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
        </el-form-item>
    </el-form>

</template>
<script>
import {add, update, getUpdate} from "@/service/szgl/ZhiFuSheZhi";
import {ruleBuilder} from '@/util/validate';
import {deepCopy, deepMerge} from "@/util/objects";
import * as service from "@/service/mdgl/MenDianXX";

export default {
    name: "ZhiFuSheZhiList",
    beforeCreate() {
        let {required} = ruleBuilder;
        this.rules = {
            sxcs: [required()], // 沙箱测试
            aliAppId: [required()], // aliAppId
            aliPrivateKey: [required()], // aliPrivateKey
            aliPubilcKey: [required()], // aliPubilcKey
            wxApiKey: [required()], // wxApiKey
            wxAppId: [required()], // wxAppId
            wxMchId: [required()], // wxMchId
            md: [required()], // 门店
            mdid: [required()], // 门店Id
        }
        this.titlePrefix = '支付设置';
        this.defaultForm = {
            id: null,
            sxcs: "false", // 沙箱测试
            aliAppId: "", // aliAppId
            aliPrivateKey: "", // aliPrivateKey
            aliPubilcKey: "", // aliPubilcKey
            wxApiKey: "", // wxApiKey
            wxAppId: "", // wxAppId
            wxMchId: "", // wxMchId
            md: "", // 门店
            mdid: "", // 门店Id
        };
        this.addService = add;
        this.updateService = update;
        this.getUpdateService = getUpdate;
    },
    data() {
        return {
            loading: false,
            form: deepCopy(this.defaultForm),
            options: []
        }
    },
    mounted() {
        this.getConfig();
        service.checkboxList().then((response) => {
            this.options = response.data

        })
    },
    methods: {
        clearValidate() {
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            });
        },
        getConfig() {
            getUpdate().then((response) => {
                if (response.data) {
                    this.form = deepMerge(this.defaultForm, response.data);
                } else {
                    this.form = deepMerge(this.defaultForm, {});
                }
                //清空校验
                this.clearValidate();
            })
        },
        async save() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
            } catch (e) {
                this.loading = false;
                return;
            }
            let api = this.form.id ? update : add;
            api(this.form).then((response) => {
                this.$message.success(response.msg);
            }).finally(() => {
                this.loading = false;
            });
        },
        mdchange(val) {
            let item = this.options.find(o => o.id = val);
            this.form.md = item.menDianMC;
        }
    }
}
</script>

<style scoped>
</style>
