import request from '@/util/request'


//添加保存
export function add(data) {
    return request({
        url: '/zhi-fu-she-zhi/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/zhi-fu-she-zhi/get-update/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/zhi-fu-she-zhi/update',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'zhi-fu-she-zhi-list',
    component: () => import('@/view/szgl/zfsz/ZhiFuSheZhiList'),
    name: 'ZhiFuSheZhiList',
    meta: {title: '支付设置', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"支付设置基础列表","methodUrl":"/zhi-fu-she-zhi/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"支付设置添加保存","methodUrl":"/zhi-fu-she-zhi/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"支付设置获取编辑数据","methodUrl":"/zhi-fu-she-zhi/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"支付设置获取详情数据","methodUrl":"/zhi-fu-she-zhi/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"支付设置编辑保存","methodUrl":"/zhi-fu-she-zhi/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"支付设置删除","methodUrl":"/zhi-fu-she-zhi/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"支付设置批量删除","methodUrl":"/zhi-fu-she-zhi/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
*/
